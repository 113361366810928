import { applySnapshot, flow, getEnv, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions, setRequestToken } from "../../utils/RequestOptions";
import {
	getStructuredAppleSubsDetails,
	getStructuredClientSubsDetails,
} from "../../utils/Utilities";
import { fetchApi } from "../../utils/CustomFetch";

const ClientSubscriptionPlans = types.model({
	appSubscriptionPlanID: types.maybeNull(types.number),
	isSelectedForPurchase: types.maybeNull(types.boolean),
	amount: types.maybeNull(types.number),
	frequency: types.maybeNull(types.number),
	subscriptionName: types.maybeNull(types.string),
	appSubscriptionID: types.maybeNull(types.number),
	categoryName: types.maybeNull(types.string),
	duration: types.maybeNull(types.number),
	clientType: types.maybeNull(types.number),
	isSkipTrial: types.maybeNull(types.boolean),
	clientID: types.maybeNull(types.number),
	trialDuration: types.maybeNull(types.number),
	trialFrequency: types.maybeNull(types.number),
	isPaymentDone: types.maybeNull(types.boolean),
});

const AppleClientSubscriptionPlans = types.model({
	appleHealthSubscriptionPlanID: types.maybeNull(types.number),
	isSelectedForPurchase: types.maybeNull(types.boolean),
	amount: types.maybeNull(types.number),
	frequency: types.maybeNull(types.number),
	subscriptionName: types.maybeNull(types.string),
	appleHealthGroupID: types.maybeNull(types.number),
	groupName: types.maybeNull(types.string),
	duration: types.maybeNull(types.number),
	isSkipTrial: types.maybeNull(types.boolean),
	tenantID: types.maybeNull(types.number),
	isPaymentDone: types.maybeNull(types.boolean),
});

const Role = types.model({
	id: types.number,
	title: types.string,
});

const AuthDetail = types.model({
	clinicId: types.maybeNull(types.number),
	userId: types.maybeNull(types.number),
	userSNo: types.maybeNull(types.string),
	firstName: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	status: types.maybeNull(types.number),
	error: types.maybeNull(types.string),
	userKey: types.maybeNull(types.string),
	token: types.maybeNull(types.string),
	roleList: types.maybeNull(types.array(Role)),
	jwtToken: types.maybeNull(types.string),
	jwtExpiryToken: types.maybeNull(types.number),
	isNewUser: types.maybeNull(types.boolean),
	isEmailVerified: types.maybeNull(types.boolean),
	isLegalDocumentAcknowledged: types.maybeNull(types.boolean),
	isSubscriptionPaymentDone: types.maybeNull(types.boolean),
	clientSubscriptionPlans: types.maybeNull(
		types.array(ClientSubscriptionPlans)
	),
	tenantID: types.maybeNull(types.number),
	appleHealthClientSubscriptionPlans: types.maybeNull(
		types.array(AppleClientSubscriptionPlans)
	)
});

const Tenants = types.model({
	tenantID: types.number,
	tenantName: types.string,
});

export const Auth = types
	.model({
		details: types.maybeNull(AuthDetail),
		refreshTokenStatus: types.optional(
			types.enumeration("State", ["pending", "done", "error"]),
			"pending"
		),
		tenants: types.maybeNull(types.array(Tenants)),
		resendPin: types.maybeNull(types.boolean)
	})
	.views((self) => ({
		get userName() {
			return `${self.details?.firstName} ${self.details?.lastName ? self.details?.lastName : ""
				}`;
		},
		get isContentCreator() {
			return self.details?.roleList.some(
				(role) => role.title === "CONTENT CREATOR"
			);
		},
		get isPatient() {
			return self.details?.roleList.some(
				(role) => role.title === "PATIENT"
			);
		},
		get isClinician() {
			return self.details?.roleList.some(
				(role) => role.title === "CLINICIAN"
			);
		},
		get isPresenter() {
			return self.details?.roleList.some(
				(role) => role.title === "PRESENTER"
			);
		},
		get isContentProducer() {
			return self.details?.roleList.some(
				(role) => role.title === "CONTENT PRODUCER"
			);
		},
		get isLMSAdmin() {
			return self.details?.roleList.some(
				(role) => role.title === "LMS ADMIN"
			);
		},
		get isSuperAdmin() {
			return self.details?.roleList?.some(
				(role) => role.title === "SUPER ADMIN"
			);
		},
		get isProductManager() {
			return self.details?.roleList?.some(
				(role) => role.title === "PRODUCT MANAGER"
			);
		},
		get isSubscriptionPaymentPending() {
			return !!(
				self?.details?.jwtToken &&
				!self.details?.isSubscriptionPaymentDone &&
				self.details?.clientSubscriptionPlans?.length
			);
		},
		get getSubscriptionIds() {
			return self.details?.clientSubscriptionPlans?.map(
				(currentSubscription) => currentSubscription?.appSubscriptionID
			);
		},
		get isCertificationConsultant() {
			return self.details?.roleList.some(
				(role) => role.title === "CERTIFICATION CONSULTANT"
			);
		},
	}))
	.actions((self) => {
		return {
			login: flow(function* login(data, snackbar, navigate, callback) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/account/login`,
						{
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						}
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json?.status !== RESPONSE_STATUS.UNAUTHORISED
					) {
						if (json?.jwtToken) {
							const parsedJSON = JSON.parse(JSON.stringify(json));
							if (parsedJSON?.clientSubscriptionPlans) {
								parsedJSON.clientSubscriptionPlans =
									getStructuredClientSubsDetails(
										parsedJSON.clientSubscriptionPlans
									);
							}
							if (
								parsedJSON?.appleHealthClientSubscriptionPlans
									?.length
							) {
								parsedJSON.appleHealthClientSubscriptionPlans =
									getStructuredAppleSubsDetails(
										parsedJSON.appleHealthClientSubscriptionPlans
									);
							}
							self.updateAuth(parsedJSON);
							callback(parsedJSON);
						} else {
							self.updateAuth(json);
							self.refreshTokenStatus = "done";
							callback();
						}
					} else {
						snackbar.showMessage(
							json.message || json.error,
							null,
							null,
							{
								severity: "error",
							}
						);
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			sendUserPin: flow(function* sendUserPin(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/send-pin-email`,
						{
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						}
					);
					const json = yield response.json();
					if (response.status === 200) {
						self.resendPin = false;
						getEnv(self).notifier.enqueue(
							"Pin Successfully Sent!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			verifyUserEmail: flow(function* verifyUserEmail(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/verifyEmail`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === 200) {
						getEnv(self).notifier.enqueue(
							"Email Verified!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			sendForgotPasswordEmail: flow(function* sendForgotPasswordEmail(
				data,
				callback = () => { }
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/send-forgotPassword-email`,
						{
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						}
					);
					const json = yield response.json();
					if (response.status === 200) {
						getEnv(self).notifier.enqueue(
							"Email Successfully Sent!!",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			sendSetPasswordEmail: flow(function* sendSetPasswordEmail(
				data,
				callback = () => { }
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/send-setPassword-email`,
						{
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						}
					);
					const json = yield response.json();
					if (response.status === 200) {
						getEnv(self).notifier.enqueue(
							"Email Successfully Sent!!",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			checkForgotPasswordEmail: flow(function* checkForgotPasswordEmail(
				key,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/check-forgotPassword-email?userKey=${key}`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();

					if (response.status === 200) {
						json["userSNo"] = json["userKey"];
						self.details = json;
						callback(json);
					} else {
						snackbar.showMessage(json.message, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			resetPassword: flow(function* resetPassword(
				data,
				snackbar,
				callback
			) {
				try {
					var request = {
						email: data.email,
						userKey: data.userKey,
						newPassword: data.password,
						tenantId: data.tenantId
					};
					const response = yield fetchApi(
						`${ROOT_URL}/api/forgotPassword`,
						getRequestOptions("POST", request)
					);
					const json = yield response.json();

					if (response.status === 200) {
						callback();
					} else {
						snackbar.showMessage(json.message, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			changePassword: flow(function* changePassword(
				request,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/changePassword`,
						getRequestOptions("POST", request)
					);
					const json = yield response.json();
					if (response.status === 200) {
						snackbar.showMessage("Password Changed Successfully", null, null, {
							severity: "success",
						});
						return true;
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
						return false;
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			isPermissionGrant: flow(function* isPermissionGrant(
				menuPath,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/user/IsPermissionGrant?menuPath=${menuPath}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();

					if (response.status === 200) {
						callback(json);
					} else {
						getEnv(self).notifier.enqueue(json.message, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			verifyUserPin: flow(function* verifyUserPin(
				userid,
				email,
				pin,
				doNotAskSecurityPin,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/verify-user-pin`,
						getRequestOptions("POST", {
							id: userid,
							key: self.details?.userSNo,
							userEmail: email,
							pin: pin,
							doNotAskSecurityPin: doNotAskSecurityPin,
						})
					);
					const json = yield response.json();

					if (response.status === 200) {
						const parsedJSON = JSON.parse(JSON.stringify(json));
						if (parsedJSON?.clientSubscriptionPlans) {
							parsedJSON.clientSubscriptionPlans =
								getStructuredClientSubsDetails(
									parsedJSON.clientSubscriptionPlans
								);
						}
						if (
							parsedJSON?.appleHealthClientSubscriptionPlans
								?.length
						) {
							parsedJSON.appleHealthClientSubscriptionPlans =
								getStructuredAppleSubsDetails(
									parsedJSON.appleHealthClientSubscriptionPlans
								);
						}
						self.resendPin = false;
						self.updateAuth(parsedJSON);
						callback(parsedJSON);
					} else {
						if (json.error == 'Incorrect Pin!') { self.resendPin = true; }
						snackbar.showMessage(
							json.message || json.error,
							null,
							null,
							{
								severity: "error",
							}
						);
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			refreshToken: flow(function* refreshToken(navigate, callback) {
				self.refreshTokenStatus = "pending";
				try {
					const response = yield fetch(
						`${ROOT_URL}/api/account/refreshToken`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.UNAUTHORISED) {
						if (callback) {
							callback(null);
						} else {
							navigate("/auth/login");
						}
					} else if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.jwtToken !== "" &&
						json.jwtToken
					) {
						self.updateAuth(json);
						if (callback) {
							callback(json);
						}
						self.refreshTokenStatus = "done";
					} else {
						if (callback) {
							callback(null);
						}
						getEnv(self).notifier.enqueue(json.message, "error");
						self.refreshTokenStatus = "error";
						navigate("/auth/login");
					}
				} catch (e) {
					if (callback) {
						callback(null);
					}
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					self.refreshTokenStatus = "error";
					navigate("/auth/login");
				}
			}),
			logout: flow(function* logout(snackbar, navigate) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/account/logout`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();

					if (response.status === 200) {
						snackbar.showMessage(json.message, null, null, {
							severity: "success",
						});
						self.details = null;
						if (window.location.host.includes("drtruitt")) {
							navigate("/auth/login");
						} else {
							window.location.href = "/published-courses"
							//navigate("/published-courses");
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			updateAuth(data) {
				self.details = data;
				self.tenants = null;
				if (data.tenants && data.tenants.length > 1) {
					self.tenants = data.tenants;
				}
				setRequestToken(data.jwtToken);
			},
			updateRoles(data) {
				applySnapshot(self.details, {
					...self.details,
					roleList: data.map((item) => {
						return { id: item.id, title: item.title };
					}),
				});
			},
			updateAcknowledge(data) {
				self.details.isLegalDocumentAcknowledged = data;
			},
			register: flow(function* register(data, snackbar, callback) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/account/register/anonymoususer`,
						{
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						}
					);
					const json = yield response.json();
					if (response.status === 200) {
						callback();
					} else {
						snackbar.showMessage(
							json.message || json.error,
							null,
							null,
							{
								severity: "error",
							}
						);
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			checkoutAppSubscription: flow(function* checkoutAppSubscription(
				data,
				snackbar,
				callback = () => { }
			) {
				self.subscriptionCheckoutStatus = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/cart/checkoutappsubscription`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						!(json?.error || json?.errors?.length)
					) {
						self.subscriptionCheckoutStatus = "done";
						self.details.isSubscriptionPaymentDone = true;
						callback();
					} else {
						snackbar.showMessage(response?.error, null, null, {
							severity: "error",
						});
						self.subscriptionCheckoutStatus = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.subscriptionCheckoutStatus = "error";
				}
			}),
			getTenantsByEmail: flow(function* getTenantsByEmail(email) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/account/getTenantsByEmail?email=${email}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === 200) {
						self.tenants = json;
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			})
		};
	});
