import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

export const SurveyStats = types.model({}).actions((self) => {
	return {
		loadById: flow(function* loadById(patientId, surveyId, snackbar) {
			try {
				const response = yield fetchApi(
					`${ROOT_URL}/api/Survey/getDateForPatientSurveyGraph?patientId=${patientId}&surveyId=${surveyId}`,
					getRequestOptions("GET", null)
				);
				const data = yield response.json();
				if (response.status === RESPONSE_STATUS.SUCCESS) {
					return { status: 200, data: data };
				} else {
					snackbar.showMessage(data?.error, null, null, {
						severity: "error",
					});
					return { status: 400 };
				}
			} catch (error) {
				snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					severity: "error",
				});
				return { status: 500 };
			}
		}),
		loadByIdForPatient: flow(function* loadByIdForPatient(surveyId, snackbar) {
			try {
				const response = yield fetchApi(
					`${ROOT_URL}/api/Survey/getDateForPatientSurveyGraphByPatient?surveyId=${surveyId}`,
					getRequestOptions("GET", null)
				);
				const data = yield response.json();
				if (response.status === RESPONSE_STATUS.SUCCESS) {
					return { status: 200, data: data };
				} else {
					snackbar.showMessage(data?.error, null, null, {
						severity: "error",
					});
					return { status: 400 };
				}
			} catch (error) {
				snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					severity: "error",
				});
				return { status: 500 };
			}
		}),
		loadByIdForPresenter: flow(function* loadByIdForPresenter(surveyId, courseId, courseSNo, snackbar) {
			try {
				const response = yield fetchApi(
					`${ROOT_URL}/api/Survey/GetSurveyGraphDataForPresenter?surveyId=${surveyId}&courseId=${courseId}&courseSNo=${courseSNo}`,
					getRequestOptions("GET", null)
				);
				const data = yield response.json();
				if (response.status === RESPONSE_STATUS.SUCCESS) {
					return { status: 200, data: data };
				} else {
					snackbar.showMessage(data?.error, null, null, {
						severity: "error",
					});
					return { status: 400 };
				}
			} catch (error) {
				snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					severity: "error",
				});
				return { status: 500 };
			}
		}),
	};
});
