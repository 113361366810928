import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const tempRegistrants = types.model({
  id: types.number,
  cartItemId: types.number,
  email: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
});

const cartItems = types.model({
  cartItemId: types.number,
  previewId: types.number,
  title1: types.maybeNull(types.string),
  title2: types.maybeNull(types.string),
  price: types.number,
  discountedPrice: types.maybeNull(types.number),
  imageURL: types.maybeNull(types.string),
  quantity: types.number,
  productType: types.number,
  batchId: types.maybeNull(types.number), //new field added
  sNo: types.string,
  batchName: types.maybeNull(types.string),
  tempRegistrants: types.array(tempRegistrants),
});

export const OrderItems = types
  .model({
    items: types.array(cartItems),
    totalQuantity: types.maybeNull(types.number),
    totalPublicItemsQuantity: types.maybeNull(types.number),
    courseIds: types.array(types.number),
    trackIds: types.array(types.number),
    fetch_all_status: types.optional(
      types.enumeration("fetch_all_status", ["pending", "done", "error"]),
      "pending"
    ),
    status: "done",
  })
  .views((self) => {
    return {
      get other() {
        return self.items.find((item) => item.name === "Other (specify):")?.id;
      },
    };
  })
  .actions((self) => {
    return {
      load: flow(function* load(userId, snackbar) {
        //self.status = "pending";
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/cart/cartitem?userId=${userId}`,
            getRequestOptions("GET", null)
          );
          const json = yield response.json();
          if (response.status === RESPONSE_STATUS.SUCCESS) {
            self.items = json;
            if (json.length > 0) {
              self.totalQuantity = json
                .map((o) => o.quantity)
                .reduce((a, c) => a + c);
              self.totalOrderAmount = json
                .map((o) => o.discountedPrice)
                .reduce((a, c) => a + c);
              self.totalDiscountedAmount = json
                .map((o) => o.price)
                .reduce((a, c) => a + c);
              self.courseIds = json
                .filter((i) => i.productType == 2)
                .map((j) => j.previewId);
              self.trackIds = json
                .filter((i) => i.productType == 1)
                .map((j) => j.previewId);
            } else {
              self.totalOrderAmount = 0;
              self.totalQuantity = 0;
              self.totalDiscountedAmount = 0;
            }
            self.status = "done";
          } else {
            snackbar.showMessage(json?.error, null, null, {
              severity: "error",
            });
            self.status = "error";
          }
        } catch (error) {
          snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
            severity: "error",
          });
          self.status = "error";
        }
      }),
      addToCart: flow(function* addToCart(data, snackbar, callback = () => {}) {
        self.status = "pending";
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/cart/cartitem`,
            getRequestOptions("POST", data)
          );
          const json = yield response.json();
          if (response.status === 200 && !json?.error) {
            if (data.activityPreviewID) {
              self.courseIds.push(data.activityPreviewID);
            }
            if (data.trackPreviewID) {
              self.trackIds.push(data.trackPreviewID);
            }
            callback();
          } else if (response.status === 200 && !json?.error) {
            snackbar.showMessage(json.error, null, null, {
              severity: "error",
            });
          } else {
            snackbar.showMessage(json.error, null, null, {
              severity: "error",
            });
          }
          self.status = "done";
        } catch (error) {
          snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
            severity: "error",
          });
          self.status = "error";
        }
      }),
      removeFromCart: flow(function* addToCart(
        cartItemID,
        activityPreviewId,
        snackbar
      ) {
        self.status = "pending";
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/cart/cartitem?cartItemID=${cartItemID}`,
            getRequestOptions("DELETE", null)
          );
          if (response.status === 200) {
            self.courseIds.pop(activityPreviewId);
            self.status = "done";
            const items = JSON.parse(JSON.stringify(self.items));
            const itemIdx = items.findIndex(
              (currentItem) => currentItem.cartItemId === cartItemID
            );
            self.items.splice(itemIdx, 1);
            self.totalQuantity -= items[itemIdx].quantity;
          } else {
            snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
              severity: "error",
            });
          }
        } catch (error) {
          snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
            severity: "error",
          });
          self.status = "error";
        }
      }),
      udpateCartItem: flow(function* udpateCartItem(data, snackbar) {
        //self.status = "pending";
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/cart/cartitem`,
            getRequestOptions("PUT", data)
          );
          //self.status = "done";
        } catch (error) {
          snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
            severity: "error",
          });
          //self.status = "error";
        }
      }),
      setOfflineQuantity(quantity) {
        self.totalPublicItemsQuantity = quantity;
      },
      addItemsToCart: flow(function* addItemsToCart(
        data = [],
        snackbar,
        callback = () => {}
      ) {
        self.status = "pending";
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/cart/cartitems`,
            getRequestOptions("POST", data)
          );
          const json = yield response.json();
          if (response.status === 200 && !json?.error) {
            // if (data.activityPreviewID) {
            // 	self.courseIds.push(data.activityPreviewID);
            // }
            // if (data.trackPreviewID) {
            // 	self.trackIds.push(data.trackPreviewID);
            // }
            callback();
          } else if (response.status === 200 && !json?.error) {
            snackbar.showMessage(json.error, null, null, {
              severity: "error",
            });
          } else {
            snackbar.showMessage(json.error, null, null, {
              severity: "error",
            });
          }
          self.status = "done";
        } catch (error) {
          snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
            severity: "error",
          });
          self.status = "error";
        }
      }),
      setOfflineOrderItems(items = []) {
        const cartItems = items.map((currentItem) => ({
          cartItemId: 0,
          previewId: currentItem?.activityPreviewId,
          title1: currentItem?.courseTitle1 || "",
          title2: currentItem?.courseTitle2 || "",
          price: currentItem?.price * currentItem?.quantity || 0,
          discountedPrice:currentItem?.discountedPrice===0 ? currentItem?.price : currentItem?.discountedPrice * currentItem?.quantity,
          imageURL:
            currentItem?.coursePreviewMainImage?.activityPreviewImageUrl,
          quantity: currentItem?.quantity || 1,
          productType: currentItem?.productType || 2,
          sNo: currentItem?.courseSNo || "",
          batchId: currentItem?.schedule?.batchId,
          batchName: currentItem?.schedule?.batchName || "",
        }));

        if (items.length > 0) {
          self.items = cartItems;
          self.totalQuantity = cartItems
            .map((o) => o.quantity)
            .reduce((a, c) => a + c);
          self.totalOrderAmount = cartItems
            .map((o) => o.discountedPrice)
            .reduce((a, c) => a + c);
          self.totalDiscountedAmount = cartItems
            .map((o) => o.price)
            .reduce((a, c) => a + c);
          self.courseIds = cartItems
            .filter((i) => i.productType == 2)
            .map((j) => j.previewId);
          self.trackIds = cartItems
            .filter((i) => i.productType == 1)
            .map((j) => j.previewId);
        } else {
          self.items = [];
          self.totalOrderAmount = 0;
          self.totalQuantity = 0;
          self.totalDiscountedAmount = 0;
          self.courseIds = [];
          self.trackIds = [];
        }
      },
    };
  });
