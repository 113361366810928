import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { blobToBase64 } from "../../helpers";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const UploadItem = types.model({
	uploadId: types.maybeNull(types.number),
	uploadType: types.maybeNull(types.string),
	uploadURL: types.maybeNull(types.string),
	uploadContentType: types.maybeNull(types.string),
	uploadFile: types.maybeNull(types.string),
	uploadSection: types.maybeNull(types.number),
});

const SessionLinkItem = types.model({
	sessionLinkAddress: types.maybeNull(types.string),
	sessionLinkId: types.maybeNull(types.number),
	sessionLinkTitle: types.maybeNull(types.string),
	sessionLinkThumbnail: types.maybeNull(types.string)
});

const CourseLinkItem = types.model({
	courseLinkAddress: types.maybeNull(types.string),
	courseLinkId: types.maybeNull(types.number),
	courseLinkSection: types.maybeNull(types.number),
	courseLinkTitle: types.maybeNull(types.string),
	courseLinkThumbnail: types.maybeNull(types.string)
});

const SurveyItem = types.model({
	surveyId: types.maybeNull(types.number),
	// dueDate: types.maybeNull(types.string),
	surveyName: types.maybeNull(types.string)
});

const PostPreSurveyItem = types.model({
	surveyId: types.maybeNull(types.number),
	// surveyDueDate: types.maybeNull(types.string),
	surveyName: types.maybeNull(types.string),
	isMandatory: types.maybeNull(types.boolean),
	isSubmitted: types.maybeNull(types.boolean),
});

const SessionItem = types.model({
	name: types.maybeNull(types.string),
	sessionId: types.maybeNull(types.number),
	sessionSNo: types.maybeNull(types.string),
	sessionUpload: types.array(UploadItem),
	sessionLink: types.array(SessionLinkItem),
	sessionSurvey: types.array(SurveyItem),
});

const DaysItem = types.model({
	courseDaysId: types.maybeNull(types.number),
	courseDaysSNo: types.maybeNull(types.string),
	courseSession: types.array(SessionItem),
	name: types.maybeNull(types.string),
});

const CoursePreviewMainImage = types.model({
	activityPreviewId: types.maybeNull(types.number),
	activityPreviewImageContentType: types.maybeNull(types.string),
	activityPreviewImageFile: types.maybeNull(types.string),
	activityPreviewImageUrl: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const CourseItem = types.model({
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseName: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	courseDays: types.array(DaysItem),
	courseUpload: types.array(UploadItem),
	courseLink: types.array(CourseLinkItem),
	isZoomEnabled: types.maybeNull(types.boolean),
	isZoomPaused: types.maybeNull(types.boolean),
	userInstructions: types.maybeNull(types.string),
	nextLiveSession: types.maybeNull(types.string),
	nextLiveSessionDate: types.maybeNull(types.string),
	isZoomExpired: types.maybeNull(types.boolean),
	title: types.maybeNull(types.string),
	subTitle: types.maybeNull(types.string),
	miniTitle: types.maybeNull(types.string),
	isZoomButtonVisible: types.maybeNull(types.boolean), 
	coursePreviewMainImage: types.maybeNull(CoursePreviewMainImage),
	zoomMeetingId: types.maybeNull(types.string),
	zoomMeetingTitle: types.maybeNull(types.string),
	coursePostSurveys: types.array(PostPreSurveyItem),
	coursePreparatorySurveys: types.array(PostPreSurveyItem),
});

export const CourseView = types
	.model({
		details: types.maybeNull(CourseItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			load: flow(function* load(
				courseId,
				courseSNo,
				batchId,
				batchSNo,
				snackbar
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCourseByIdForPatient?courseId=${courseId}&courseSNo=${courseSNo}&batchId=${batchId}&batchSNo=${batchSNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.details = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadFile: flow(function* loadFile(
				url,
				contentType,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCoursePreviewImageByURL?ActivityPreviewImageUrl=${url}&ActivityPreviewImageContentType=${contentType}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						var blob1 = new Blob([blob], {type: contentType});
						var blobURL = URL.createObjectURL(blob1);
						callback(blobURL);
						//const base64 = yield blobToBase64(blob);
						//callback(base64);
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
		};
	});
