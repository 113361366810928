import { applySnapshot, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const CoursePreviewLearning = types.model({
	learningId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	learning: types.maybeNull(types.string),
});

const CoursePreviewRequirement = types.model({
	requirementId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	requirement: types.maybeNull(types.string),
});

const CoursePreviewOutline = types.model({
	courseOutlineId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseOutline: types.maybeNull(types.string),
});

const CoursePreviewContinuingEducation = types.model({
	continuingEducationId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	continuingEducation: types.maybeNull(types.string),
});

const CoursePreviewCourseObjective = types.model({
	courseObjectiveId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseObjective: types.maybeNull(types.string),
});

const CoursePreviewTargetAudience = types.model({
	targetAudienceId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	targetAudience: types.maybeNull(types.string),
});

const CoursePreviewAdditionalInformation = types.model({
	additionalInformationId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	additionalInformation: types.maybeNull(types.string),
});

const CoursePreviewCourseMaterial = types.model({
	courseMaterialId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseMaterial: types.maybeNull(types.string),
});

const CoursePreviewInstructor = types.model({
	instructorId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	instructorName: types.maybeNull(types.string),
	instructorCV: types.maybeNull(types.string),
	instructorRating: types.maybeNull(types.number),
	instructorReview: types.maybeNull(types.number),
	students: types.maybeNull(types.number),
	courses: types.maybeNull(types.number),
});

const CoursePreviewSchedule = types.model({
	courseMaterialId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	scheduleTime: types.maybeNull(types.string),
	scheduleDate: types.maybeNull(types.string),
});

const CoursePreviewMainImage = types.model({
	activityPreviewId: types.maybeNull(types.number),
	activityPreviewImageContentType: types.maybeNull(types.string),
	activityPreviewImageFile: types.maybeNull(types.string),
	activityPreviewImageUrl: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const CoursePreviewVideo = types.model({
	activityPreviewId: types.maybeNull(types.number),
	activityPreviewVideoContentType: types.maybeNull(types.string),
	activityPreviewVideoLink: types.maybeNull(types.string),
	isLocationS3: types.maybeNull(types.boolean),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const CourseScheduleItem = types.model({
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	batchName: types.maybeNull(types.string),
	isZoomEnabled: types.maybeNull(types.boolean)
});

const CoursePreviewDetails = types.model({
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseDescription: types.maybeNull(types.string),
	previewTemplateId: types.maybeNull(types.number),
	imageTitle1: types.maybeNull(types.string),
	imageTitle2: types.maybeNull(types.string),
	imageTitle3: types.maybeNull(types.string),
	courseTitle1: types.maybeNull(types.string),
	courseTitle2: types.maybeNull(types.string),
	price: types.maybeNull(types.number),
	discountedPrice: types.maybeNull(types.number),
	language: types.maybeNull(types.number),
	skillLevel: types.maybeNull(types.number),
	isPurchased: types.maybeNull(types.boolean),
	registrationDeadline: types.maybeNull(types.string),
	certificate: types.maybeNull(types.boolean),
	coursePreviewLearning: types.maybeNull(types.array(CoursePreviewLearning)),
	coursePreviewMainImage: types.maybeNull(CoursePreviewMainImage),
	coursePreviewVideo: types.maybeNull(CoursePreviewVideo),
	coursePreviewRequirement: types.maybeNull(
		types.array(CoursePreviewRequirement)
	),
	coursePreviewOutline: types.maybeNull(types.array(CoursePreviewOutline)),
	coursePreviewSchedule: types.maybeNull(types.array(CoursePreviewSchedule)),
	coursePreviewContinuingEducation: types.maybeNull(
		types.array(CoursePreviewContinuingEducation)
	),
	coursePreviewCourseObjective: types.maybeNull(
		types.array(CoursePreviewCourseObjective)
	),
	coursePreviewTargetAudience: types.maybeNull(
		types.array(CoursePreviewTargetAudience)
	),

	coursePreviewAdditionalInformation: types.maybeNull(
		types.array(CoursePreviewAdditionalInformation)
	),

	coursePreviewCourseMaterial: types.maybeNull(
		types.array(CoursePreviewCourseMaterial)
	),

	coursePreviewInstructor: types.maybeNull(
		types.array(CoursePreviewInstructor)
	),
	tags: types.maybeNull(types.array(types.string)),	
	schedule: types.maybeNull(CourseScheduleItem)
});

export const CoursePublish = types
	.model({
		items: types.array(CoursePreviewDetails),
		recommendedItems: types.array(CoursePreviewDetails),
		itemsPreview: types.array(CoursePreviewDetails),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.id === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllPublishedCourses`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.items, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadByPreviewId: flow(function* load(activtiPreviewIds,snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Common/getAllPublishedCoursesByPreviewId`,
						getRequestOptions("POST", { activityPreviewIds: activtiPreviewIds })
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.itemsPreview, json);
						//self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadPublicCourses: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Common/getAllCourses`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.items, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadRecommendations: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllCoursesByCart`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.recommendedItems, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			purchaseCourse: flow(function* purchaseCourse(id, sNo, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/purchaseCourse`,
						getRequestOptions("POST", { id: id, sNo: sNo })
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find((item) => item.courseId === id),
							{
								...self.items.find(
									(item) => item.courseId === id
								),
								isPurchased: data.isPurchased,
							}
						);
						snackbar.showMessage("Course Purchased!!", null, null, {
							severity: "success",
						});
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
		};
	});
