import { applySnapshot, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";
const registrants = types.model({
  email: types.string,
  firstName: types.string,
  lastName: types.string,
  deleted: types.boolean,
});

const orderItem = types.model({
  orderItemID: types.number,
  productID: types.number,
  productSNO: types.string,
  previewId: types.number,
  title1: types.string,
  title2: types.string,
  productType: types.string,
  imageURL: types.maybeNull(types.string),
  quantity: types.number,
  batchName: types.maybeNull(types.string),
  totalAmount: types.number,
  registrants: types.maybeNull(types.array(registrants)),
});

const orderedItems = types.model({
  couponCode: types.maybeNull(types.string),
  orderID: types.string,
  totalOrderAmount: types.number,
  totalCouponDiscount: types.number,
  orderItems: types.array(orderItem),
  purchaseDate: types.string,
});

const usersOrders = types.model({
  orders: types.array(orderedItems),
  userID: types.maybeNull(types.number),
  userName: types.maybeNull(types.string),
  userEmail: types.maybeNull(types.string),
});

const usersOrdersList = types.model({
  orderId: types.maybeNull(types.string),
})

const orderActivityList = types.model({
  courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	activityName: types.maybeNull(types.string),
})
export const OrdersReport = types
  .model({
    allOrders: types.array(usersOrders),
    OrderIdList: types.array(usersOrdersList),
    OrderActivityList: types.array(orderActivityList),
  })
  .actions((self) => {
    return {
      getOrderIdList: flow(function* getOrderIdList() {
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/cart/GetOrderIdList`,
            getRequestOptions("GET", null)
          );
          const json = yield response.json();
          if (response.status === RESPONSE_STATUS.SUCCESS) {
            self.OrderIdList=json;
          } else {
            console.error(json?.error);
          }
        } catch (error) {
          console.error(error);
        }
      }),
      getActivityList: flow(function* getActivityList() {
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/cart/GetActivityListForOrderReport`,
            getRequestOptions("GET", null)
          );
          const json = yield response.json();
          if (response.status === RESPONSE_STATUS.SUCCESS) {
            self.OrderActivityList=json;
          } else {
            console.error(json?.error);
          }
        } catch (error) {
          console.error(error);
        }
      }),

      generate: flow(function* generate(data, snackbar) {
        self.status = "pending";
        self.registrantsUpdateStatus = "pending";
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/cart/GetActivityOrderReport?courseId=${
              data.courseId ? data.courseId : ""
            }&courseSNo=${
              data.courseSNo ? data.courseSNo : ""
            }&purchaseStartDate=${
              data.purchaseStartDate ? data.purchaseStartDate : ""
            }&purchaseEndDate=${
              data.purchaseEndDate==="" ? data.purchaseStartDate : data.purchaseEndDate
            }&orderID=${data.orderID ? data.orderID : ""}`,
            getRequestOptions("GET", null)
          );
          const json = yield response.json();
          if (response.status === RESPONSE_STATUS.SUCCESS) {
            applySnapshot(self.allOrders, json);
            self.status = "done";
          } else {
            snackbar.showMessage(json?.error, null, null, {
              severity: "error",
            });
            self.status = "error";
          }
        } catch (error) {
          snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
            severity: "error",
          });
          self.status = "error";
        }
      }),
      clearGeneratedData:flow(function* clearGeneratedData(){
         applySnapshot(self.allOrders, []); 
      }),

      updateRegistrants: flow(function* addToCart(orderItemId, data, snackbar) {
        self.registrantsUpdateStatus = "pending";
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/orderhistory/updateregistrant?orderItemID=${orderItemId}`,
            getRequestOptions("POST", data)
          );
          const json = yield response.json();

          if (response.status === 200) {
            self.registrantsUpdateStatus = "done";
          } else {
            snackbar.showMessage(json.errors[0], null, null, {
              severity: "error",
            });
            self.registrantsUpdateStatus = "error";
          }
        } catch (error) {
          snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
            severity: "error",
          });
          self.registrantsUpdateStatus = "error";
        }
      }),
      saveRegistrantTemp: flow(function* saveRegistrantTemp(
        cartItemId,
        isDelete,
        data,
        snackbar
      ) {
        self.registrantsUpdateStatus = "pending";
        try {
          const response = yield fetchApi(
            `${ROOT_URL}/api/orderhistory/saveregistranttemp?cartItemId=${cartItemId}`,
            getRequestOptions("POST", data)
          );
          const json = yield response.json();

          if (response.status === 200) {
            self.registrantsUpdateStatus = "done";
            if (isDelete) {
              snackbar.showMessage(
                "Registrant deleted successfully",
                null,
                null,
                {
                  severity: "success",
                }
              );
            } else {
              snackbar.showMessage(
                "Registrant saved successfully",
                null,
                null,
                {
                  severity: "success",
                }
              );
            }
          } else if (response.status === 400) {
            snackbar.showMessage(
              "Please enter registrant details",
              null,
              null,
              {
                severity: "error",
              }
            );
            self.registrantsUpdateStatus = "error";
          } else {
            snackbar.showMessage(json.errors[0], null, null, {
              severity: "error",
            });
            self.registrantsUpdateStatus = "error";
          }
        } catch (error) {
          snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
            severity: "error",
          });
          self.registrantsUpdateStatus = "error";
        }
      }),
    };
  });
