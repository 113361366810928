import { types, flow, applySnapshot, destroy } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	RESPONSE_STATUS,
	ROOT_URL,
	CONTENT_TYPE,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const TrackPreviewLearning = types.model({
	learningId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	learningSNo: types.maybeNull(types.string),
	learning: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewRequirement = types.model({
	requirementId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	requiremenSNo: types.maybeNull(types.string),
	requirement: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewOutline = types.model({
	trackOutlineId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	trackOutlineSNo: types.maybeNull(types.string),
	courseOutline: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewContinuingEducation = types.model({
	continuingEducationId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	continuingEducationSNo: types.maybeNull(types.string),
	continuingEducation: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewCourseObjective = types.model({
	trackObjectiveId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	trackObjectiveSNo: types.maybeNull(types.string),
	trackObjective: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewTargetAudience = types.model({
	targetAudienceId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	targetAudienceSNo: types.maybeNull(types.string),
	targetAudience: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewAdditionalInformation = types.model({
	additionalInformationId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	additionalInformationSNo: types.maybeNull(types.string),
	additionalInformation: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewCourseMaterial = types.model({
	trackMaterialId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	trackMaterialSNo: types.maybeNull(types.string),
	trackMaterial: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewInstructor = types.model({
	instructorId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	instructorSNo: types.maybeNull(types.string),
	instructorHeadShot: types.maybeNull(types.string),
	instructorName: types.maybeNull(types.string),
	instructorCV: types.maybeNull(types.string),
	instructorRating: types.maybeNull(types.number),
	instructorReview: types.maybeNull(types.number),
	students: types.maybeNull(types.number),
	courses: types.maybeNull(types.number),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewSchedule = types.model({
	scheduleId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	scheduleSNo: types.maybeNull(types.string),
	scheduleTime: types.maybeNull(types.string),
	scheduleDate: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewHeaderImage = types.model({
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewImageContentType: types.maybeNull(types.string),
	trackPreviewImageFile: types.maybeNull(types.string),
	trackPreviewImageUrl: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const TrackPreviewVideo = types.model({
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewVideoContentType: types.maybeNull(types.string),
	trackPreviewVideoLink: types.maybeNull(types.string),
	isLocationS3: types.maybeNull(types.boolean),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	trackPreviewSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
	sNo: types.maybeNull(types.string),
});

const TrackPreviewDetails = types.model({
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	trackDescription: types.maybeNull(types.string),
	trackDeliveryMethod: types.maybeNull(types.string),
	previewTemplateId: types.maybeNull(types.number),
	imageTitle1: types.maybeNull(types.string),
	imageTitle2: types.maybeNull(types.string),
	imageTitle3: types.maybeNull(types.string),
	trackTitle1: types.maybeNull(types.string),
	trackTitle2: types.maybeNull(types.string),
	price: types.maybeNull(types.number),
	discountedPrice: types.maybeNull(types.number),
	language: types.maybeNull(types.number),
	skillLevel: types.maybeNull(types.number),
	registrationDeadline: types.maybeNull(types.string),
	isCDP: types.maybeNull(types.boolean),
	certificate: types.maybeNull(types.boolean),
	trackPreviewLearning: types.maybeNull(types.array(TrackPreviewLearning)),
	trackPreviewHeaderImage: types.maybeNull(TrackPreviewHeaderImage),
	trackPreviewVideo: types.maybeNull(TrackPreviewVideo),
	trackPreviewRequirement: types.maybeNull(
		types.array(TrackPreviewRequirement)
	),
	trackPreviewOutline: types.maybeNull(types.array(TrackPreviewOutline)),
	trackPreviewSchedule: types.maybeNull(types.array(TrackPreviewSchedule)),
	trackPreviewContinuingEducation: types.maybeNull(
		types.array(TrackPreviewContinuingEducation)
	),
	trackPreviewCourseObjective: types.maybeNull(
		types.array(TrackPreviewCourseObjective)
	),
	trackPreviewTargetAudience: types.maybeNull(
		types.array(TrackPreviewTargetAudience)
	),

	trackPreviewAdditionalInformation: types.maybeNull(
		types.array(TrackPreviewAdditionalInformation)
	),

	trackPreviewCourseMaterial: types.maybeNull(
		types.array(TrackPreviewCourseMaterial)
	),

	trackPreviewInstructor: types.maybeNull(
		types.array(TrackPreviewInstructor)
	),
});

export const TrackPublish = types
	.model({
		items: types.array(TrackPreviewDetails),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.id === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllPublishedTracks`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.items, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
		};
	});
