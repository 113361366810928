import { applySnapshot, destroy, flow, types, getEnv } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";
import FileSaver from "file-saver";

const TrackCouseItem = types.model({
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	sequenceNo: types.maybeNull(types.number),
	courseName: types.maybeNull(types.string),
	batchName: types.maybeNull(types.string),
	batchType: types.maybeNull(types.string),
	courseTitle: types.maybeNull(types.string),
});

const TrackPreparatorySurveyItems = types.model({ 
	surveyId:types.maybeNull(types.number),
	isMandatory:types.maybeNull(types.boolean),
})

const TrackPostSurveyItem= types.model({
	surveyId:types.maybeNull(types.number),
});

const TrackPreviewHeaderImage = types.model({
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewImageContentType: types.maybeNull(types.string),
	trackPreviewImageFile: types.maybeNull(types.string),
	trackPreviewImageUrl: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const continuingEducationItem = types.model({
	trackContinuingEducationId: types.number,
  	trackContinuingEducationSNo: types.maybeNull(types.string),
});

const TrackItem = types.model({
	trackId: types.maybeNull(types.number),
	certificateConsultantId: types.maybeNull(types.number),
	certificateConsultantSNo: types.maybeNull(types.string),
	trackStatus:types.maybeNull(types.number),
	trackType:types.maybeNull(types.number),
	trackName: types.maybeNull(types.string),
	trackDescription:types.maybeNull(types.string),
	trackUserInstructions:types.maybeNull(types.string),
	trackTitle1: types.maybeNull(types.string),
	trackPreviewHeaderImage: types.maybeNull(TrackPreviewHeaderImage),
	trackSNo: types.maybeNull(types.string),
	isCDP: types.maybeNull(types.boolean),
	isSequential: types.maybeNull(types.boolean),
	deliveryMethodId: types.maybeNull(types.integer),
	trackDeliveryMethod: types.maybeNull(types.string),
	trackCourse: types.maybeNull(types.array(TrackCouseItem)),
	trackPreparatorySurvey: types.maybeNull(types.array(TrackPreparatorySurveyItems)),
	trackPostSurvey:types.maybeNull(types.array(TrackPostSurveyItem)),
	isActiveCertificateVideo: types.maybeNull(types.boolean),
	tags: types.maybeNull(types.array(types.string)),
	isAppleHealth:types.maybeNull(types.boolean),
	isCETrack:types.maybeNull(types.boolean),
	continuingEducation:types.maybeNull(continuingEducationItem)
});

export const Track = types
	.model({
		items: types.array(TrackItem),
		clinicianItems: types.array(TrackItem),
		currentTrackId: types.maybeNull(types.number),
		currentTrackSNo: types.maybeNull(types.string),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.trackId === self.currentId
					);
				} else {
					return null;
				}
			},
			item(id) {
				if (id) {
					return self.items.find((item) => item.trackId === id);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllTrack`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.items, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),

			loadPurchasedTrackForClinician: flow(
				function* loadPurchasedTrackForClinician(snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/getPurchasedTrackForClinician`,
							getRequestOptions("GET", null)
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.clinicianItems = data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			loadById: flow(function* loadById(id, sNo, snackbar,callback,marketTrackCallback) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getTrackById?trackId=${id}&trackSNo=${sNo}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						const index = self.items.findIndex(
							(item) => item.trackId === id
						)
						if(index > -1){
							self.items[index] = data;
						}
						self.currentId = id;
						marketTrackCallback();
						if(data?.continuingEducation?.trackContinuingEducationId){
							callback();
						}
						self.updateContentType(CONTENT_TYPE.EDIT);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			getAllTrackDeliveryMethods: flow(
				function* getAllTrackDeliveryMethods(snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/getAllTrackDeliveryMethods`,
							getRequestOptions("GET", null)
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							// applySnapshot(self.items, data);
							self.status = "done";
							return data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			sendTrackForARFReview: flow(function* sendTrackForARFReview(
				trackId,
				trackSNo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/course/sendTrackForARFReview`,
						getRequestOptions("POST", {
							trackId: trackId,
							trackSNo: trackSNo,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 200) {
							snackbar.showMessage(
								"Activity Sent for Review!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					console.log(error);
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			save: flow(function* save(data, snackbar,shouldClose=true,callback) {
				if (self.currentId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrack`,
							getRequestOptions("POST", {...self.current, ...data})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json?.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(self.items.find((item) => item.trackId === json.trackId), json);
								applySnapshot(self.current, json);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
								if (shouldClose) {
									self.updateContentType(CONTENT_TYPE.LIST);
								}
								callback();
							}
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/createTrack`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();

						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.items.push(json);
							self.currentTrackId=json.trackId
							self.currentTrackSNo=json.trackSNo
							self.currentId = json.trackId;
							if(shouldClose){
								self.updateContentType(CONTENT_TYPE.LIST);
							}
							callback();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			updateTrackStatus: flow(function* updateTrackStatus(trackData={},snackbar,updateToList=false) {
				const trackId=trackData ? trackData?.trackId : self.currentTrackId
				const trackSNo=trackData ? trackData?.trackSNo : self.currentTrackSNo
				const response = yield fetchApi(
					`${ROOT_URL}/api/Course/updateTrackStatus`,
					getRequestOptions("POST", {
						trackId: trackId,
						trackSNo: trackSNo,
					})
				)
				const data = yield response.json();
				if (response.status === RESPONSE_STATUS.SUCCESS) {
					applySnapshot(
						self.items.find((item) => item.trackId === trackId),
						data
					);
					if(updateToList){
						self.updateContentType(CONTENT_TYPE.LIST)
					}
					snackbar.showMessage(
						"Submitted for Review Successfully!!",
						null,
						null,
						{ severity: "success" }
					)
				}
				else{
					snackbar.showMessage(data?.error, null, null, {
						severity: "error",
					});
				}
			}),

			approveTrackStatus: flow(function* approveTrackStatus(
				id,
				sNo,
				tags,
				snackbar
			){
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/approveTrackStatus`,
						getRequestOptions("POST", {
							id: id,
							sNo: sNo,
							tags: tags
						})
					)
					const data = yield response.json();
					if(response.status === RESPONSE_STATUS.SUCCESS &&
							(data.status === RESPONSE_STATUS.SUCCESS ||
							data.status === RESPONSE_STATUS.SUCCESS_ZERO)
					){
						applySnapshot(
							self.items.find((item) => item.trackId === id),
							data
						);
						self.updateContentType(CONTENT_TYPE.LIST);
						snackbar.showMessage(
							"Approved Course Successfully!!",
							null,
							null,
							{ severity: "success" }
						)
					}
					else{
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					})
				}
			}),
			rejectTrackStatus: flow(function* rejectTrackStatus(
				id,
				sNo,
				snackbar
			){
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/rejectTrackStatus`,
						getRequestOptions("POST", {
							trackId: id,
							trackSNo: sNo
						})
					)
					const data = yield response.json();
					if(response.status === RESPONSE_STATUS.SUCCESS &&
							(data.status === RESPONSE_STATUS.SUCCESS ||
							data.status === RESPONSE_STATUS.SUCCESS_ZERO)
					){
						applySnapshot(
							self.items.find((item) => item.trackId === id),
							data
						);
						
						self.updateContentType(CONTENT_TYPE.LIST);
						snackbar.showMessage(
							"Course Rejected!!",
							null,
							null,
							{ severity: "success" }
						)
					}
					else{
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",});
					}
					
				} catch (error) {
					
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					})
				}
			}),
			approveTrackStatusBySA: flow(function* approveTrackStatusBySA(
				trackId,
				trackSNo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/approveTrackStatusBySA`,
						getRequestOptions("POST", {
							trackId: trackId,
							trackSNo: trackSNo,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							if (self.items.some((item) => item.trackId === trackId)) {
								applySnapshot(
									self.items.find((item) => item.trackId === trackId),
									data
								);
							}
							else {
								self.items.push(data);
							}
							snackbar.showMessage(
								"Track Published!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					console.log(error);
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeTrack: flow(function* removeBatch(track, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteTrack`,
						getRequestOptions("POST", {
							trackSNo: track.trackSNo,
							trackId: track.trackId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(track);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			getFileVersionList: flow(function* getFileVersionList(
				fileURL,
				fileUploadType,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getFileVersionListWithUploadBy?fileURL=${fileURL}&uploadType=${fileUploadType}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						callback(json);
					} else {
						snackbar.showMessage(
							"Unable to download File",
							null,
							null,
							{
								severity: "error",
							}
						);
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),

			getFileAsDownload: flow(function* getFileAsDownload(
				fileURL,
				fileContentType,
				fileName,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getFileAsDownload?fileURL=${fileURL}&fileContentType=${fileContentType}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						FileSaver.saveAs(blob, fileName);
						callback();
					} else {
						snackbar.showMessage(
							"Unable to download File",
							null,
							null,
							{
								severity: "error",
							}
						);
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),

			getFileVersionAsDownload: flow(function* getFileVersionAsDownload(
				fileURL,
				fileContentType,
				versionId,
				fileName,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getFileVersionAsDownload?fileURL=${fileURL}&fileContentType=${fileContentType}&versionId=${versionId}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						FileSaver.saveAs(blob, fileName);
						callback();
					} else {
						snackbar.showMessage(
							"Unable to download File",
							null,
							null,
							{
								severity: "error",
							}
						);
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),

			addTagsToCourse: flow(function* addTagsToCourse(trackId, trackSNo, tags) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addTagsToTrack`,
						getRequestOptions("POST", {
							trackId: trackId,
							trackSNo: trackSNo,
							tags: tags,
						})
					);
					const data = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						(data.status === RESPONSE_STATUS.SUCCESS ||
							data.status === RESPONSE_STATUS.SUCCESS_ZERO)
					) {
						if (self.items.some((item) => item.trackId === trackId)) {
							applySnapshot(
								self.items.find((item) => item.trackId === trackId),
								data
							);
							self.updateContentType(CONTENT_TYPE.LIST);
						}
						getEnv(self).notifier.enqueue(
							"Tags Updated!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(data?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			updateCE(isCE) {
				applySnapshot(self.current, {
					...self.current,
					isCETrack: isCE,
				});
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
		};
	});
