import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_ACCESSOR } from "../constants";
import { useStore } from "../hooks";
import { useSnackbar } from "../lib/snackbar";

const CheckAuth = ({ children }) => {
	const { resource, auth, orderItems } = useStore();
	const snackbar = useSnackbar();
	const navigate = useNavigate();
	const [isAuthSet, setAuth] = useState(false);
	const [isWithouAuth, setWithoutAuth] = useState(false);
	const [isRemoveUnautorizedToken, setRemoveUnautorizedToken] =
		useState(false);

	useEffect(() => {
		const getAuthDetails = async () => {
			await auth.refreshToken(navigate, (details) => {
				if (details && details?.jwtToken) {
					auth.updateAuth(details);
					callResources();
				} else {
					setWithoutAuth(true);
				}
			});
		};

		getAuthDetails();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const callResources = () =>
		resource.load(auth?.details?.userId, snackbar, (isResourceLoaded) => {
			if (isResourceLoaded) {
				setAuth(true);
			} else if (!isResourceLoaded) {
				auth.updateAuth({});
				setRemoveUnautorizedToken(true);
			}
		});

	useEffect(() => {
		if (
			auth?.details?.jwtToken &&
			!isAuthSet &&
			(auth?.isSuperAdmin ||
				auth?.details?.isSubscriptionPaymentDone ||
				auth?.details?.tenantID === 1)
		) {
			callResources();
		} else if (
			(auth?.details?.jwtToken &&
				!isAuthSet &&
				!auth?.details?.isSubscriptionPaymentDone) ||
			(!auth?.details?.jwtToken && isRemoveUnautorizedToken)
		) {
			setAuth(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth?.details?.jwtToken, isRemoveUnautorizedToken]);

	useEffect(() => {
		let localCartItemData = localStorage.getItem(
			LOCAL_STORAGE_ACCESSOR.CART_ITEM
		);
		localCartItemData = localCartItemData
			? JSON.parse(localCartItemData)
			: "";
		if (localCartItemData?.length && auth?.details?.jwtToken && isAuthSet) {
			const cartItems = localCartItemData?.map((element) => ({
				productID: element?.activityPreviewId,
				userId: auth.details?.userId,
				productType: 2,
				quantity: element?.quantity || 1,
				scheduleId: element?.schedule?.batchId || element?.courseSchedule[0]?.batchId,
			}));
			if (!auth?.isSuperAdmin) {
				orderItems.addItemsToCart(cartItems, snackbar, () => {
					navigate("/manage/order");
					orderItems.setOfflineQuantity(0);
				});
			}
			localStorage.removeItem(LOCAL_STORAGE_ACCESSOR.CART_ITEM);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth?.details?.jwtToken, isAuthSet]);

	return isAuthSet || isWithouAuth ? children : <></>;
};

export default observer(CheckAuth);
